<template>
  <div class="noSite">
    <img src="@/assets/noSite.png" class="noSite-img" />
    <h1 class="noSite-title">暂无站点</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.noSite {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
  background: #f9fafb;
  .noSite-img {
    width: 460px;
    display: block;
    margin: auto;
  }
  .noSite-title {
    font-size: 32px;
    color: #666;
    font-weight: normal;
    text-align: center;
    margin-top: 30px;
  }
}
</style>
